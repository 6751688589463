// Variables
:root {
  --avatar-size: 40px;
}

// The cards
:root .post-card {
  margin-inline: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-bottom: 0.75rem;
  content-visibility: auto;
  & hr {
    margin: 0.75rem -1rem;
  }
}

@media screen and (min-width: 992px) {
  :root .post-card {
    margin-inline: 1.5rem;
  }
}

// Elements inside cards
:root .user-link {
  text-decoration: none;
  color: unset;

  &:hover.user-name,
  &:hover .user-url {
    text-decoration: underline;
  }
}

.user-name {
  font-weight: 500;
}

.user-url {
  color: var(--mat-sys-outline);
}

.post-action {
  color: var(--mat-sys-outline);
}

.avatar-container {
  display: flex;
  height: var(--avatar-size);
  width: var(--avatar-size);
  justify-content: center;
}

.avatar {
  border-radius: 8px;
  display: flex;
  object-fit: cover;
}

.post-emoji {
  display: inline-flex;
  max-height: 1.5em;
  max-width: 1.5em;
  vertical-align: middle;
}

.post-text {
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}

.mention {
  height: unset;
  width: unset;
  border-radius: unset;
  background-color: unset;
  padding: unset;
  margin-right: unset;
  -webkit-user-select: unset;
  user-select: unset;
}

.mention > span {
  margin: 0;
}

:root .mention {
  display: inline-block;
  padding: 0 4px 0 4px;
  color: var(--mat-sys-on-surface);
  background-color: var(--mat-sys-secondary-container);
  font-weight: 500;
  text-decoration: none;
  border-radius: var(--mat-sys-corner-small);

  &:hover {
    text-decoration: underline;
  }
}

.tag {
  padding: 0 0.75ch;
  cursor: pointer;
  background-color: var(--mat-sys-primary);
  color: var(--mat-sys-on-primary) !important;
  text-decoration: none;
  border-radius: var(--mat-sys-corner-small);
  font-size: 0.8em;
  line-height: 1.3;
  padding-top: 2px;
  word-break: break-all;
}

.shortened-post {
  max-height: 350px;
  overflow: hidden;
}

.quoted-post, .ask-container {
	padding: 0.5em;	
	cursor: pointer !important;
  border-radius: 12px;
  border: 1px solid var(--mat-sys-outline-variant);
  max-height: 256px;
  flex-shrink: 0;
  overflow: hidden;
  margin-bottom: 4px;
  position: relative;
}

// youtube player
.yt-thumbnail {
  max-width: 100%;
  width: 100%;
}

.yt-watermark {
  max-width: 100%;
}

.watermark {
  /* Used to position the watermark */
  position: relative;
}

.watermark__inner {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Absolute position */
  left: 0px;
  position: absolute;
  top: 0px;

  /* Take full size */
  height: 100%;
  width: 100%;
}

.watermark__body {
  max-height: 15%;
  max-width: 15%;
}
