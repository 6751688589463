a,
a:visited,
.ql-snow a {
  color: var(--wafrn-blue);
}

ul,
ol {
  padding-inline-start: 0.75rem;
  margin: 0 0 0.75em;
}

li {
  list-style-type: inside circle;
  margin-left: 1.5em;
}

img,
video {
  display: block;
  max-height: 100%;
}

hr {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: var(--mat-sys-outline-variant);
}

blockquote {
  border-inline-start: 3px solid var(--mat-sys-outline);
  padding-inline-start: 0.75rem;
  margin: 0 0 0.75em;
}

code {
  display: inline;
  padding: 0 0.2em;
  font-size: 0.875rem;
  background-color: var(--mat-sys-surface-container);
  border: 1px solid var(--mat-sys-outline-variant);
  border-radius: 4px;
}

pre {
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  background-color: var(--mat-sys-surface-container);
  border: 1px solid var(--mat-sys-outline-variant);
  border-radius: var(--mat-sys-corner-small);
  overflow-x: auto;

  & > code {
    all: unset;
  }
}

details {
  cursor: pointer;
}

textarea {
  scrollbar-color: var(--mat-sys-outline) transparent;
}