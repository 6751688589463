@use '@angular/material' as mat;
@use './app/styles/basic-html.scss';
@use './app/styles/dialog.scss';
@use './app/styles/material-overrides.scss';
@use './app/styles/post-card.scss';
@use './app/styles/quil.scss';
@use './app/styles/theme-switcher.scss';
@use './app/styles/viewer.min.css';
@use './app/styles/viewer-overrides.scss';
@use './app/styles/vlite.css';
@use './app/styles/vlite-overrides.scss';

// Color scheme default
@import './assets/themes/default.css';

html {
  color-scheme: light dark;
  @include mat.theme(
    (
      color: mat.$violet-palette,
      density: 0
    )
  );
}

// HACK: Have to make dummy theme to initialize typography
$theme: mat.define-theme(
  (
    color: (
      primary: mat.$violet-palette
    )
  )
);

@include mat.typography-hierarchy($theme);

:root {
  @include mat.badge-overrides(
    (
      background-color: var(--mdc-filled-button-container-color, var(--mat-sys-primary))
    )
  );
}

// Variables
:root {
  --wafrn-blue: light-dark(#125e59, #96d8d1);
  --mat-sys-label-small-size: 0.688rem;

  --info-card-info-background: light-dark(#d5e3fe, #3a475d);
  --info-card-warning-background: light-dark(#d7c3b8, #52443c);
}

// Global style fixes
* {
  box-sizing: border-box;
  scrollbar-color: var(--mat-sys-outline-variant) transparent;
  scrollbar-width: thin;
}

html {
  height: 100%;
  background-color: var(--mat-sys-surface);
  overflow-wrap: anywhere;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  margin: 0;
}

.wafrn-container {
  max-width: 800px;
}

.wafrn-content {
  padding-top: 4rem;
}

// No JavaScript
#no-js {
  position: fixed;
  inset: 0;
  background: var(--mat-sys-background);
  cursor: wait;
  z-index: 99999999; // Go on top of splash screen!

  & .text-muted {
    color: var(--mat-sys-outline);
  }
}

// Splashscreen
#splash {
  position: fixed;
  inset: 0;
}
#splash {
  background: var(--mat-sys-background);
  cursor: wait;
  z-index: 2; // Minimum
  transition: display, opacity;
  transition-duration: 120ms;
  transition-behavior: allow-discrete;
  transition-timing-function: ease-in;
}
#splash-icon,
#splash-icon img {
  pointer-events: none;
  user-select: none;
}
#splash-text {
  margin: 0;
  height: 0; // Do not shift image based on text height
  max-width: 40ch;
  text-align: center;
  color: var(--mat-sys-outline);
}
#splash-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: translate 120ms ease-in;
}

#splash.loaded {
  display: none;
  opacity: 0;

  & #splash-content {
    translate: 0 -6px;
  }
}

// Utility styles
.subtle-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.force-comic-sans {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
